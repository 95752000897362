<template>
  <div class="app flex-row align-items-center">
    <div class="container">
      <b-row class="justify-content-center">
        <b-col md="8">
          <b-card-group>
            <b-card no-body class="p-4">
              <b-card-body>
                <b-form>
                  <h1>Nouveau mot de passe</h1>
                  <p class="text-danger" v-if="hasError">{{errorMessage}}</p>
                  <p class="text-muted" v-else>Créez un mot de passe pour accéder à votre compte</p>

                  <c-input container-class="mb-4 required-input" type="password" label="Nouveau mot de passe"
                           placeholder="Entrez votre mot de passe" v-model="password" :state="passwordState">
                    Votre mot de passe doit avoir au moins 8 caractères, contenir au moins un chiffre, au moins un
                    caractère spécial et au moins un caractère alphabétique
                  </c-input>

                  <c-input container-class="mb-4 required-input" type="password" label="Confirmation du mot de passe"
                           placeholder="Confirmez le mot de passe" v-model="passwordConfirm"
                           :state="passwordConfirmState">
                    Les mots de passe ne correspondent pas
                  </c-input>

                  <b-row>
                    <b-col cols="6">
                      <button-spinner variant="success" class="px-4" @click="resetPassword" :fetching="fetching">
                        Valider
                      </button-spinner>
                    </b-col>
                  </b-row>
                </b-form>
              </b-card-body>
            </b-card>
            <b-card no-body class="text-white bg-primary py-5 d-md-down-none" style="width:44%">
              <b-card-body class="text-center">
                <div>
                  <h2>Aide</h2>
                  <p>
                    Il est important de choisir un mot de passe pas trop simple, mais assez significatif pour que vous
                    ne puissiez pas l'oublier.
                  </p>
                  <!--<b-button variant="primary" class="active mt-3" href="/register">M'inscrire !</b-button>-->
                </div>
              </b-card-body>
            </b-card>
          </b-card-group>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
  import {Api, Auth, Toast} from "../../helpers";

  export default {
    name: 'PasswordReset',
    title: 'PIA - Réinitialisation de mot de passe',
    data() {
      return {
        password: '',
        passwordConfirm: '',
        submitted: false,
        error: null,
        fetching: false
      }
    },
    watch: {
      error(e) {
        if (e)
          Toast.error(e)
      }
    },
    computed: {
      passwordState() {
        return !this.submitted || this.password.length >= 8 ? null : false
      },
      passwordConfirmState() {
        return !this.submitted || this.passwordConfirm === this.password ? null : false
      },
      hasError() {
        return !!this.error
      },
      errorMessage() {
        return this.error ? this.error.message : null
      }
    },
    methods: {
      valid() {
        return this.password.trim() !== '' && this.passwordConfirm === this.password
      },
      resetPassword() {
        this.submitted = true
        if (!this.valid()) return

        this.fetching = true
        Api.post('/password-reset', {
          token: this.$route.params.token,
          password: this.password
        })
          .then(res => {
            const user = res.data.data
            if (res.data.status === 'success' && user) {
              console.log('ok')
              Auth.authenticateUser(user)
              this.$router.push('/home')
            }
            else {
              this.error = res.data.error
            }
          })
          .catch(error => {
            this.error = {
              message: 'Echec de la connexion au serveur'
            }
          })
          .then(() => {
            this.fetching = false
          })
      }
    }
  }
</script>

<style scoped>
  h1, .h1 {
    font-size: 1.6rem;
  }
</style>
